<template>
  <div id="questions-section" class="w-100 question-bg">
    <div
      class="container mx-auto flex flex-col items-center text-white text-xs lg:px-3 px-5 lg:py-16 py-12"
    >
      <DefaultTitle lineClass="bg-blue" :light="true" :center="true">
        HAVE QUESTIONS? <span class="font-normal">FEEL FREE TO ASK</span>
      </DefaultTitle>
      <div class="flex lg:flex-row flex-col mt-12">
        <HrefButton
          size="regular"
          icon="question-phone"
          label="+1817-357-8462"
          class="bg-blue lg:w-96 w-full border-blue px-6 py-3 justify-center text-white mr-6 lg:text-2xl text-xl special-shadow shadow-gap mb-4"
        />
        <HrefButton
          :onClicked="startChat"
          size="regular"
          icon="question-chat"
          label="BEGIN A CHAT"
          class="intercom_link border-blue lg:w-96 w-full px-6 py-3 justify-center text-white lg:text-2xl text-xl special-shadow shadow-gap mb-4"
        />
      </div>
    </div>
  </div>
</template>
<script>
import DefaultTitle from "./DefaultTitle.vue";
import HrefButton from "./HrefButton.vue";

export default {
  name: "HaveQuestions",
  components: {
    DefaultTitle,
    HrefButton,
  },
  methods: {
    startChat() {
      this.emitter.emit("showChat", true);
    },
  },
};
</script>
<style lang=""></style>
